// General
import { FC, PropsWithChildren, useContext } from 'react';
import styled, { css } from 'styled-components';

// Helpers
import { WalletConnectTranslations } from '../app/Layout';

// Icons
import Close from '../../icons/CloseIcon';
import { BREAKPOINT_XL } from '../../../styles/Breakpoints';

const StyledModal = styled.div`
  background: #fff;
  width: MIN(870px, calc(100% - var(--content-indent)));
  position: relative;
  padding: 3.25rem 2rem 2.5rem;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.54);
  border-radius: 10px;

  ${BREAKPOINT_XL} {
    padding: 4rem 0;
  }
`;

const CloseButton = styled(Close)`
  color: var(--color-black);
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: unset;
  border: 0;

  &:hover {
    cursor: pointer;
  }
`;

const CloseIcon = styled.div`
  height: 16px;
  fill: inherit;
`;

const Cover = css`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
`;

const Overlay = styled.div`
  ${Cover};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled.div`
  ${Cover};
  background: rgba(0, 0, 0, .2);
  position: absolute;
  z-index: -1;
`;

export interface IModalProps {
  close: () => void;
}

const WalletConnectModal: FC<PropsWithChildren<IModalProps>> = (props) => {
  const { close, children } = props;
  const t = useContext(WalletConnectTranslations);

  console.log('close =>',close);

  return (
    <Overlay>
      <Backdrop onClick={close} />
      <StyledModal>
        <CloseButton onClick={close} aria-label={t?.close}>
          <CloseIcon />
        </CloseButton>
        {children}
      </StyledModal>
    </Overlay>
  );
};

export default WalletConnectModal;
