// General
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { FC } from 'react';
import useFeatureToggle from './hooks/useFeatureToggle';
import Layout from './components/layout/app/Layout';
import Training from './components/content/training';
import Profile from './components/content/profile';
import BallerDetail from './components/content/ballers/BallerDetail';
import WalletRoute from './components/layout/guards/WalletRoute';
import { Shop } from './components/content/shop';
import { History } from './components/content/history';

import { GlobalStyles } from './styles/GlobalStyles';
import { RecoilRoot } from 'recoil';
import { Mint } from './components/content/mint';
import { Claim } from './components/content/claim';
import Page404 from './components/content/404';
import AccessBlocked from './components/content/AccessBlocked';
import Dashboard from './components/content/dashboard';
import { ApiProvider } from './services/ApiProvider';
import ShopItemDetail from './components/content/shop/ShopItemDetail';
import RaffleDetail from './components/content/shop/RaffleDetail';

const App: FC = () => {
  const { TRAINING_ACTIVE, SHOP_ACTIVE, CLAIM_ACTIVE, MINT_ACTIVE } = useFeatureToggle();

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ApiProvider>
          <Layout>
            <Outlet />
          </Layout>
        </ApiProvider>
      ),
      children: [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: '/profile',
          element: (
            <WalletRoute>
              <Profile />
            </WalletRoute>
          ),
        },
        {
          path: '*',
          element: <Page404 />,
        },
        {
          path: 'access-blocked',
          element: <AccessBlocked />,
        },
        TRAINING_ACTIVE
          ? {
              path: 'training',
              element: (
                <WalletRoute>
                  <Training />
                </WalletRoute>
              ),
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop',
              element: <Shop />,
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop/history',
              element: (
                <WalletRoute>
                  <History />
                </WalletRoute>
              ),
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop/items/:id',
              element: <ShopItemDetail />,
            }
          : {},
        SHOP_ACTIVE
          ? {
              path: 'shop/raffles/:id',
              element: <RaffleDetail />,
            }
          : {},
        {
          path: 'ballers/:ballerId',
          element: (
            <WalletRoute>
              <BallerDetail />
            </WalletRoute>
          ),
        },
        MINT_ACTIVE
          ? {
              path: 'mint',
              element: (
                <WalletRoute>
                  <Mint />
                </WalletRoute>
              ),
            }
          : {},
        CLAIM_ACTIVE
          ? {
              path: 'claim',
              element: (
                <WalletRoute>
                  <Claim />
                </WalletRoute>
              ),
            }
          : {},
      ],
    },
  ]);

  return (
    <RecoilRoot>
      <GlobalStyles />
      <RouterProvider router={router} />
    </RecoilRoot>
  );
};

export default App;
