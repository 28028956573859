import styled, { css } from 'styled-components';
import Field, { useFieldError, useFieldProps } from './Field';
import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import { InputError } from './TextInput';

const Wrapper = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 0.375rem;

  :hover {
    cursor: pointer;
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const StyledCheckmark = styled(CheckmarkIcon)<{ checked: boolean }>`
  grid-area: 1 / 1;
  width: var(--size, 1rem);
  height: var(--size, 1rem);
  padding: 5px;
  pointer-events: none;
  user-select: none;
  color: var(--color-text-default);
  font-weight: 400;

  transition: opacity 150ms ease-out, visibility 150ms ease-out, color 150ms ease-out;
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
`;

const StyledInput = styled(Field)`
  grid-area: 1 / 1;
  width: var(--size, 1rem);
  height: var(--size, 1rem);
  border: 2px solid var(--color-text-default);
  flex-shrink: 0;

  :disabled {
    border-color: var(--color-grey-600);
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const Children = styled.div`
  grid-column: 2;
`;

const StyledInputError = styled(InputError)`
  padding-inline: 1.5rem;
`;

interface ICheckboxProps extends PropsWithChildren {
  name: string;
  className?: string;
  disabled?: boolean;
  optional?: boolean;
}

export default function Checkbox(props: ICheckboxProps) {
  const { name, children, className, optional = false, disabled = false } = props;

  const fieldProps = useFieldProps(name, optional);
  const error = useFieldError(name);
  const formContext = useFormContext();
  const checked = formContext.watch(name);

  return (
    <div className={className}>
      <Wrapper disabled={disabled}>
        <StyledInput type={'checkbox'} {...fieldProps} optional={optional} />

        <StyledCheckmark checked={checked} />

        <Children>{children}</Children>
      </Wrapper>

      {error?.message && <StyledInputError>{error.message}</StyledInputError>}
    </div>
  );
}
