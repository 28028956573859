// General
import styled, { css } from 'styled-components';
import { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';

// Icons
import ShopIcon from '../../icons/ShopIcon';
import TrainingIcon from '../../icons/TrainingIcon';
import ProfileIcon from '../../icons/ProfileIcon';
//import MintIcon from '../../icons/MintIcon';

// Hooks
import useFeatureToggle from '../../../hooks/useFeatureToggle';

// Utils
import { Translations } from '../../../utils/Translations';

// Styles
import { BREAKPOINT_XL } from '../../../styles/Breakpoints';

// Components
import IconLink from '../../common/IconLink';
import HomeIcon from '../../icons/HomeIcon';

const Nav = styled.nav`
  display: none;

  ${BREAKPOINT_XL} {
    position: sticky;
    z-index: 1;
    top: calc(5.75rem + var(--content-spacing) / 2);
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border-radius: 10px;
    grid-template-columns: auto;
    padding-inline: 1.5rem;
    padding-block: 2.5rem;
    height: calc(100vh - var(--nav-height) - (var(--content-spacing) * 2));
    background: linear-gradient(180deg, #000 0%, #1b0f1c 100%);
    box-shadow: 0 0 20px 0 #000;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const ActiveLinkIndicator = styled.span<{ currentIndex: number }>`
  position: absolute;
  display: flex;
  top: 1.75rem;
  right: -1px;
  width: 4px;
  height: 4.5rem;
  background: #f8c57d;
  border: 1px solid var(--color-primary);
  border-radius: 4px 0 0 4px;
  box-shadow: 0 0 13.6px 0 rgba(255, 187, 14, 0.52), 0 0 10.8px 3px rgba(236, 22, 22, 0.32);
  transform: translateY(calc(6rem * ${({ currentIndex }) => currentIndex}));
  transition: transform 0.25s ease-in-out;
`;

const ActiveLinkStyling = css`
  color: var(--color-primary);

  svg {
    filter: drop-shadow(0 0 2px rgba(206, 74, 52, 0.8)) drop-shadow(0 0 10px rgba(206, 74, 52, 0.7));
  }
`;

const ActiveWrapper = styled.span<{ manualActive?: boolean }>`
  a {
    ${({ manualActive }) => manualActive && ActiveLinkStyling};
  }
`;

const StyledLink = styled(NavLink)<{ active?: boolean; disabled?: boolean }>`
  font-family: var(--font-family-headline);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
  display: grid;
  gap: 0.375rem;
  justify-items: center;

  &:hover {
    text-decoration: none;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.8)) drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));
  }

  &.active {
    ${({ disabled }) => !disabled && ActiveLinkStyling}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`;

const IconLinkWrapper = styled.div`
  display: none;

  @media (min-height: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-start: auto;
    gap: 0.5rem;
  }
`;

export interface INavigationProps {
  className?: string;
}

export const NavigationIconLinks = [
  {
    icon: 'discord',
    link: {
      title: 'Discord',
      href: 'https://discord.gg/mooncourt',
      target: '_blank',
    },
    hasBorder: false,
  },
  {
    icon: 'opensea',
    link: {
      title: 'Opensea',
      href: 'https://opensea.io/collection/mooncourt-baller',
      target: '_blank',
    },
    hasBorder: false,
  },
  {
    icon: 'twitter',
    link: {
      title: 'Twitter',
      href: 'https://twitter.com/mooncourtxyz',
      target: '_blank',
    },
    hasBorder: false,
  },
  {
    icon: 'youtube',
    link: {
      title: 'Youtube',
      href: 'https://www.youtube.com/@Mooncourtxyz',
      target: '_blank',
    },
  },
  {
    icon: 'fortnite',
    link: {
      title: 'Fortnite',
      href: 'https://www.fortnite.com/@mooncourt.xyz',
      target: '_blank',
    },
    hasBorder: false,
  },
];

export default function Navigation(props: INavigationProps) {
  const { isConnected } = useAccount();
  const history = useLocation();
  const { TRAINING_ACTIVE, SHOP_ACTIVE } = useFeatureToggle();

  const currentNavIndex = useMemo(() => {
    if (history.pathname.includes('shop')) {
      return 3;
    } else if (history.pathname.includes('training')) {
      return 2;
    } else if (history.pathname.includes('profile') || history.pathname.includes('items') || history.pathname.includes('raffles')) {
      return 1;
    } else {
      return 0;
    }
  }, [history.pathname]);

  return (
    <Nav className={props.className}>
      <ActiveLinkIndicator currentIndex={currentNavIndex} />
      {
        // <StyledLink to={'/claim'}>
        //   <MintIcon />
        //   Claim
        // </StyledLink>
      }

      {
        // <StyledLink to={'/mint'}>
        //   <MintIcon />
        //   {navigationLabelMint}
        // </StyledLink>
      }

      <StyledLink to={'/'}>
        <HomeIcon />
        {Translations.navigation.navigationLabelDashboard}
      </StyledLink>

      <ActiveWrapper manualActive={currentNavIndex === 1}>
        <StyledLink disabled={!isConnected} to={'/profile'}>
          <ProfileIcon />
          {Translations.navigation.navigationLabelProfile}
        </StyledLink>
      </ActiveWrapper>

      <StyledLink disabled={!isConnected || !TRAINING_ACTIVE} to={'/training'}>
        <TrainingIcon />
        {Translations.navigation.navigationLabelTraining}
      </StyledLink>

      <StyledLink disabled={!SHOP_ACTIVE} to='/shop'>
        <ShopIcon />
        {Translations.navigation.navigationLabelShop}
      </StyledLink>
    </Nav>
  );
}
