import styled from 'styled-components';
import { InputHTMLAttributes } from 'react';
import Field, { useFieldError, useFieldProps } from './Field';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled(Field)<{ hasError: boolean }>`
  border-radius: .625rem;
  border-width: 1px;
  border-color: var(${({ hasError }) => hasError ? '--color-signal-red' : '--color-text-default'});
  border-style: solid;
  box-shadow: 0 0 10px 0 ${({ hasError }) => hasError ? 'rgba(246, 63, 33, 0.7)' : 'rgba(255, 255, 255, .7)'};
  padding: .75rem;
  line-height: 1;

  :disabled {
    color: var(--color-grey-700);
    border-color: var(--color-grey-700);
    box-shadow: none;
  }
`;

export const InputHint = styled.p`
  font-size: .875rem;
  font-weight: 700;
  padding-inline: .875rem;
  line-height: 1;
  margin-block-start: .375rem;
`;

export const InputError = styled(InputHint)`
  color: var(--color-signal-red);
  margin-block-start: .375rem;
`;

interface ITextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  optional?: boolean;
  hint?: string;
}

export default function TextInput(props: ITextInputProps) {
  const {
    name,
    hint,
    optional = false,
    ...inputProps
  } = props;

  const fieldProps = useFieldProps(name, optional);
  const error = useFieldError(name);

  return (
    <Wrapper>
      <Input {...fieldProps} {...inputProps} hasError={!!error?.message} optional={optional} />

      {hint && <InputHint>{hint}</InputHint>}

      {error?.message && <InputError>{error.message}</InputError>}
    </Wrapper>
  );
}