import styled from 'styled-components';
import { BREAKPOINT_MD } from '../../styles/Breakpoints';
import { dispatchConnectWallet } from '../../utils/walletConnect/event';
import { Button } from '../common/Button';
import Wysiwyg from '../common/Wysiwyg';
import { Glass } from './Container';
import { Translations } from '../../utils/Translations';
import Mooncourt from '../icons/Mooncourt';

export interface IConnectWallet {
  headline: string;
  text: string;
}

const Wrapper = styled.div`
  ${Glass};
  border: 2px solid rgba(255, 255, 255, 1);
  inline-size: min(100%, 850px);
  display: grid;
  justify-items: center;
  text-align: center;
  place-self: center;
  padding-block: 2.5rem;
  padding-inline: 2rem;

  ${BREAKPOINT_MD} {
    padding-block: 3rem 3.5rem;
    padding-inline: 5rem;
  }
`;

const Logo = styled(Mooncourt)`
  width: 1.875rem;
  height: 1.45831rem;
  margin-bottom: 0.5rem;
  color: var(--color-primary);
`;
const Headline = styled.h2`
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-family: Oswald;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

const StyledText = styled(Wysiwyg)`
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem;
  margin-bottom: 4.5rem;
`;

const StyledButton = styled(Button)`
  padding-inline: 2rem;
`;

interface IConnectWalletProps {
  className?: string;
}

export default function ConnectWallet(props: IConnectWalletProps) {
  const { className } = props;

  return (
    <Wrapper className={className}>
      <Logo />

      <Headline>{Translations.walletConnect.connectHeadline}</Headline>

      <StyledText content={Translations.walletConnect.connectText} />

      <StyledButton theme='tertiary' onClick={dispatchConnectWallet}>
        Connect wallet
      </StyledButton>
    </Wrapper>
  );
}
