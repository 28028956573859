// General
import { atom, useResetRecoilState } from 'recoil';

// Types
import { INFT } from '../types/NFT';

// Effects
import { TimeStampEffect } from './effects/TimeStampEffect';
import { LevelProps } from '../services/level';
import { RaffleTicketProps, ShopHistoryItemProps } from '../services/shop';
import { IGlobalAddressProps } from '../services/address';

interface ITimeEffect {
  timeStamp?: number;
}

interface INFTState extends ITimeEffect {
  nfts: Array<INFT>;
}

interface IShopItemState extends ITimeEffect {
  items: ShopHistoryItemProps[];
}

interface IRaffleTicketState extends ITimeEffect {
  items: RaffleTicketProps[];
}

// Boosters of the user
export const BoosterState = atom<INFTState>({
  key: 'booster-state',
  default: {
    nfts: [],
  },
  effects: [TimeStampEffect],
});

// Ballers of the user
export const BallerState = atom<INFTState>({
  key: 'baller-state',
  default: {
    nfts: [],
  },
  effects: [TimeStampEffect],
});

export const CometsState = atom<INFTState>({
  key: 'comets-state',
  default: {
    nfts: [],
  },
  effects: [TimeStampEffect],
});

// ShopItems of the use
export const ShopItemState = atom<IShopItemState>({
  key: 'shop-item-state',
  default: {
    items: [],
  },
  effects: [TimeStampEffect],
});

export const RaffleTicketState = atom<IRaffleTicketState>({
  key: 'raffle-ticket-state',
  default: {
    items: [],
  },
  effects: [TimeStampEffect],
});

interface IClaimablePointsState extends ITimeEffect {
  tokens: Array<{
    tokenId: number;
    points: number;
  }>;
  claimablePoints?: number;
  pointRate?: number;
}

// Claimable coins of the user
export const ClaimablePointsState = atom<IClaimablePointsState>({
  key: 'claimable-points-state',
  default: {
    tokens: [],
    claimablePoints: undefined,
    pointRate: undefined,
  },
  effects: [TimeStampEffect],
});

interface IPointsState {
  points: number;
  fetchData?: () => Promise<void>;
}

export const PointsState = atom<IPointsState | null>({
  key: 'points-state',
  default: null,
});

export const LevelState = atom<LevelProps | null>({
  key: 'level-state',
  default: null,
});

export const UserAddressState = atom<IGlobalAddressProps | null | undefined>({
  key: 'address-state',
  default: undefined,
});

export const useResetUserState = function () {
  const resetBoosterState = useResetRecoilState(BoosterState);
  const resetBallerState = useResetRecoilState(BallerState);
  const resetClaimablePointsState = useResetRecoilState(ClaimablePointsState);
  const resetPointState = useResetRecoilState(PointsState);
  const resetLevelState = useResetRecoilState(LevelState);
  const resetShopItemState = useResetRecoilState(ShopItemState);
  const resetUserAddressState = useResetRecoilState(UserAddressState);

  return function () {
    resetBoosterState();
    resetBallerState();
    resetClaimablePointsState();
    resetPointState();
    resetLevelState();
    resetShopItemState();
    // resetUserAddressState();
  };
};
