// General
import { PropsWithChildren } from 'react';
import { useAccount } from 'wagmi';

// Components
import ConnectWallet from '../../content/ConnectWallet';

export interface IWalletRouteProps extends PropsWithChildren {}

export default function WalletRoute({ children }: IWalletRouteProps) {
  const { isConnected } = useAccount();
  return (isConnected ? children : <ConnectWallet />) as JSX.Element;
}
