import styled from 'styled-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDashboardApi } from '../../../services/dashboard';
import { useAccount } from 'wagmi';
import { DashboardHeader, DashboardHeadline } from './index';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import StatTile, { IStatTileProps } from './StatTile';
import { useIsApiReady } from '../../../services/ApiProvider';
import Pagination from '../../common/Pagination';
import ConnectWallet from '../ConnectWallet';
import { useTrainingApi } from '../../../services/contract/stake';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-rows: min-content 1fr;

  ${BREAKPOINT_XXL} {
    grid-column: 13 / 21;
  }
`;

const GlassCard = styled.div`
  display: grid;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.01);
  box-shadow: 0 0 22px 0 #000;
  backdrop-filter: blur(2px);
  padding: 1.25rem 0.75rem;
  flex-direction: column;
  gap: 1rem 1.25rem;

  .swiper {
    height: 100%;
    width: 100%;
  }

  ${BREAKPOINT_LG} {
    padding: 1.25rem 2rem;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  place-items: center;
  gap: 1.25rem 0.875rem;

  ${BREAKPOINT_MD} {
    gap: 2.5rem;
    grid-template-columns: repeat(2, min-content);
    place-content: center;
  }

  ${BREAKPOINT_XXL} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
`;

const StyledConnectWallet = styled(ConnectWallet)`
  display: grid;
  place-content: center;
  height: 100%;

  h2 {
    font-size: 1.625rem;

    ${BREAKPOINT_MD} {
      font-size: 1.875rem;
    }
  }

  ${BREAKPOINT_MD} {
    padding-inline: 5rem;
  }
`;

export interface IUserStatsProps {
  headline: string;
}

interface IUserStats {
  claimablePoints: number;
  dailyPoints: number;
  pointsSpent: number;
  totalPoints: number;
}

export default function UserStats(props: IUserStatsProps) {
  const { headline } = props;
  const [stats, setStats] = useState<IUserStats | null>(null);
  const { address } = useAccount();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { getStats } = useDashboardApi();
  const { getTrainingStatus } = useTrainingApi();
  const isApiReady = useIsApiReady();
  const swiperRef = useRef<SwiperRef | null>(null);
  const { isConnected } = useAccount();

  function changeIndex(newIndex: number) {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(newIndex);
    }
  }

  async function fetchData() {
    const [dashboardResponse, trainingResponse] = await Promise.all([getStats(address!), getTrainingStatus(address!)]);

    setStats({
      claimablePoints: trainingResponse.claimablePoints,
      dailyPoints: trainingResponse.dailyPoints,
      pointsSpent: dashboardResponse.pointsSpent,
      totalPoints: dashboardResponse.totalPoints,
    });
  }

  useEffect(() => {
    if (isConnected) {
      isApiReady && fetchData();
    } else {
      setStats(null);
    }
  }, [isApiReady, isConnected]);

  const StatItems: IStatTileProps[] = useMemo(() => {
    return [
      { value: stats ? stats.claimablePoints : '-', name: 'moonpoints', label: 'Claimable' },
      { value: stats ? stats.dailyPoints : '-', name: 'moonpoints', label: 'Per Day' },
      { value: stats ? stats.pointsSpent : '-', name: 'moonpoints', label: 'Spent in Shop' },
      { value: stats ? stats.totalPoints : '-', name: 'moonpoints', label: 'Account Balance' },
    ];
  }, [stats]);

  return (
    <Wrapper>
      <DashboardHeader>
        <DashboardHeadline>{headline}</DashboardHeadline>
      </DashboardHeader>

      {isConnected ? (
        <GlassCard>
          <Swiper
            centeredSlides={true}
            slidesPerView={1}
            spaceBetween={16}
            onSlideChange={(e) => setCurrentIndex(e.realIndex)}
            ref={swiperRef}>
            {Array(Math.ceil(StatItems.length / 4))
              .fill('')
              .map((_, index) => (
                <StyledSwiperSlide key={index}>
                  {StatItems.slice(index * 4, index * 4 + 4).map((item, index) => (
                    <StatTile key={index} {...item} />
                  ))}
                </StyledSwiperSlide>
              ))}
          </Swiper>

          {StatItems.length > 4 && (
            <Pagination currentIndex={currentIndex} changeIndex={changeIndex} amount={Math.ceil(StatItems.length / 4)} />
          )}
        </GlassCard>
      ) : (
        <StyledConnectWallet />
      )}
    </Wrapper>
  );
}
