import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import { useForm } from 'react-hook-form';
import useToast from '../../../hooks/useToast';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import Modal from '../../common/Modal';
import { Button } from '../../common/Button';
import TextInput from '../../common/form/TextInput';
import { Form } from '../../common/form/Form';
import Select from '../../common/form/Select';
import { Translations } from '../../../utils/Translations';
import { Countries } from '../../../utils/Countries';
import { useSetRecoilState } from 'recoil';
import { UserAddressState } from '../../../states/AppData';
import { useAddressApi } from '../../../services/address';
import { useIsApiReady } from '../../../services/ApiProvider';

const StyledModal = styled(Modal)`
  max-height: 90vh;
  overflow-y: auto;

  ${BREAKPOINT_MD} {
    padding-inline: 6.75rem;
  }
`;

const Headline = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-block-end: 1rem;
`;

const Text = styled.p`
  margin-block-end: 2.5rem;
`;

const FormItemsWrapper = styled.div`
  display: grid;
  gap: 1.125rem 1.75rem;
  margin-block-end: 1rem;
  align-items: start;

  ${BREAKPOINT_MD} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FormHeadline = styled.h5`
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-block-end: 1.25rem;
  grid-column: 1 / -1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.75rem;
`;

const DialogButton = styled(Button)`
  padding-inline: 1.25rem;
`;

interface IGlobalAddressModalProps {
  toggleModal: () => void;
  setLoading: (value: boolean) => void;
}

export interface IAddressFormProps {
  firstname: string;
  lastname: string;
  company?: string;
  city: string;
  street: string;
  postalCode: string;
  houseNumber: string;
  country: string;
  email: string;
  phone?: string;
}

export default function GlobalAddressModal(props: IGlobalAddressModalProps) {
  const { toggleModal, setLoading } = props;
  const { address } = useAccount();
  const form = useForm<IAddressFormProps>();
  const [addressId, setAddressId] = useState<string | null>(null);
  const { addToast } = useToast();
  const setUserAddressState = useSetRecoilState(UserAddressState);
  const addressApi = useAddressApi();
  const isApiReady = useIsApiReady();

  async function submitHandler(formData: IAddressFormProps) {
    if (isApiReady) {
      setLoading(true);
      toggleModal();
      try {
        const updatedAddress = await addressApi.updateAddress({
          wallet: address!,
          id: addressId || undefined,
          address: formData,
        });

        setUserAddressState((updatedAddress as any).doc);

        if (Translations.notifications.successfulSaveAddressNotification) {
          addToast({
            ...Translations.notifications.successfulSaveAddressNotification,
            type: 'success',
          });
        }
      } catch (error) {
        if (Translations.notifications.failedSaveAddressNotification) {
          addToast({
            ...Translations.notifications.failedSaveAddressNotification,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const countries = useMemo(() => {
    return [...Countries.sort((a: any, b: any) => a.name.localeCompare(b.name))];
  }, []);

  useEffect(() => {
    if (address && isApiReady) {
      setLoading(true);
      addressApi
        .getAddress(address)
        .then((data) => {
          form.setValue('firstname', data.firstname);
          form.setValue('lastname', data.lastname);
          form.setValue('company', data?.company || '');
          form.setValue('city', data.city);
          form.setValue('street', data.street);
          form.setValue('postalCode', data.postalCode);
          form.setValue('houseNumber', data.houseNumber);
          form.setValue('country', data.country);
          form.setValue('email', data.email);
          form.setValue('phone', data?.phone || '');
          setAddressId((data as any)?.id || null);
        })
        .catch((error) => {
          addToast({
            ...Translations.notifications.failedFetchAddressNotification,
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    }
  }, [address, isApiReady]);

  return (
    <StyledModal close={toggleModal}>
      <Headline>Address</Headline>

      <Text>Please fill in the form correctly and truthfully. We will only use your address to process your orders.</Text>

      <Form form={form} onSubmit={submitHandler}>
        <FormItemsWrapper>
          <FormHeadline>Shipping address</FormHeadline>
          <TextInput name={'firstname'} placeholder={'First Name'} />
          <TextInput name={'lastname'} placeholder={'Last Name'} />
          <TextInput name={'company'} placeholder={'Company'} optional />
          <TextInput name={'email'} placeholder={'E-Mail'} />
          <TextInput name={'street'} placeholder={'Street'} />
          <TextInput name={'houseNumber'} placeholder={'House Number'} />
          <TextInput name={'postalCode'} placeholder={'Postal Code'} />
          <TextInput name={'city'} placeholder={'City'} />
          <Select name={'country'} items={countries} placeholder={'Select your Country'} />
          <TextInput name={'phone'} placeholder={'Phone'} optional />
        </FormItemsWrapper>

        <ButtonWrapper>
          <DialogButton theme='secondary' onClick={toggleModal} type={'button'}>
            Cancel
          </DialogButton>

          <DialogButton theme='secondary'>Save</DialogButton>
        </ButtonWrapper>
      </Form>
    </StyledModal>
  );
}
