import { AxiosInstance } from 'axios';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAccount } from 'wagmi';
import { useNavigate } from 'react-router-dom';

export interface IApiContextProps {
  apiInstance: AxiosInstance;
  isApiReady: boolean;
}

const ApiContext = createContext<IApiContextProps>({} as any);

export const useApi = () => useContext(ApiContext).apiInstance;
export const useIsApiReady = () => useContext(ApiContext).isApiReady;

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const { address, isConnected } = useAccount();
  const navigate = useNavigate();

  const [context, setContext] = useState<IApiContextProps>({
    apiInstance: axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'Accept-Language': 'en',
      },
    }),
    isApiReady: false,
  });

  useEffect(() => {
    if (!isConnected) {
      setContext({ ...context, isApiReady: false });
    }

    const walletHeaderInterceptor = context.apiInstance.interceptors.request.use((config: any) => {
      if (address) {
        config.headers.wallet = address;
      }

      return config;
    }, undefined);

    const blacklistedResponseInterceptor = context.apiInstance.interceptors.response.use(undefined, (error) => {
      if ([401, 403].includes(error?.response?.status || error?.response?.data.blacklisted)) {
        console.error('blacklistedResponseInterceptor =>', error);
        if (isConnected) {
          navigate('/access-blocked');
        }
      }
    });

    if (address) {
      setContext({
        ...context,
        isApiReady: true,
      });
    }

    return () => {
      context.apiInstance.interceptors.request.eject(walletHeaderInterceptor);
      context.apiInstance.interceptors.response.eject(blacklistedResponseInterceptor);
    };
  }, [address, isConnected]);

  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
};
