import styled from 'styled-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { INews } from '../../../types/news';
import { useNewsApi } from '../../../services/news';
import { DashboardHeadline } from './index';
import NewsSliderCard from './NewsSliderCard';
import Pagination from '../../common/Pagination';
import { BREAKPOINT_MD, BREAKPOINT_XXL } from '../../../styles/Breakpoints';
import { EffectCards } from 'swiper';
import { useIsApiReady } from '../../../services/ApiProvider';
import LinkHandler from '../../common/LinkHandler';
import { useAccount } from 'wagmi';

const Wrapper = styled.div`
  grid-column: 1 / -1;
  margin-inline: calc(-1 * var(--content-spacing));

  ${BREAKPOINT_MD} {
    display: grid;
  }

  ${BREAKPOINT_XXL} {
    grid-column: 1 / 12;
    grid-template-rows: min-content 1fr min-content;
    margin-inline: 0;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 1.25rem;
  padding-inline: var(--content-spacing);

  ${BREAKPOINT_XXL} {
    padding-inline: 0;
  }
`;

const Container = styled.div<{ progress: number }>`
  position: relative;
  container-type: inline-size;
  padding-inline-start: calc(var(--content-spacing) + ${({ progress }) => `${progress * 5}%`});
  padding-inline-end: calc(var(--content-spacing) + ${({ progress }) => `${5 - progress * 5}%`});
  transition: padding 0.125s ease-in-out;

  .swiper-slide {
    border-radius: 1.25rem;
    overflow: hidden;
    box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 0.8);
  }

  ${BREAKPOINT_XXL} {
    padding-inline-start: ${({ progress }) => `${progress * 5}%`};
    padding-inline-end: ${({ progress }) => `${5 - progress * 5}%`};

    .swiper {
      height: 100%;
    }
  }
`;

const StyledPagination = styled(Pagination)`
  margin-block: 0.75rem 1.5rem;

  ${BREAKPOINT_MD} {
    margin-block: 0.5rem 0;
  }
`;

const MobileCardBody = styled.div`
  padding-inline: var(--content-spacing);

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const NewsTitle = styled.h5`
  font-size: 1.875rem;
  line-height: 1.2;
  margin-block-end: 0.75rem;
  text-transform: uppercase;
`;

const NewsPreviewText = styled.p`
  margin-block-end: 1.25rem;
`;

const StyledLinkHandler = styled(LinkHandler)`
  font-family: var(--font-family-headline);
  font-weight: bold;
  text-decoration: underline;

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
    }
  }
`;

export interface INewsSliderProps {
  headline: string;
  readMoreLabel: string;
}

export default function NewsSlider(props: INewsSliderProps) {
  const { headline, readMoreLabel } = props;
  const [news, setNews] = useState<INews[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const swiperRef = useRef<SwiperRef | null>(null);
  const newsApi = useNewsApi();
  const isApiReady = useIsApiReady();
  const { isConnected } = useAccount();

  function changeIndex(newIndex: number) {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(newIndex);
    }
  }

  async function fetchData() {
    const response = await newsApi.getNews();
    setNews(response);
  }

  useEffect(() => {
    if (isConnected) {
      isApiReady && fetchData();
    } else {
      fetchData();
    }
  }, [isApiReady]);

  const mobilePreviewItem = useMemo(() => news[currentIndex], [currentIndex, news]);

  return (
    <Wrapper>
      <Header>
        <DashboardHeadline>{headline}</DashboardHeadline>
      </Header>

      <Container progress={(1 / (news.length - 1)) * currentIndex}>
        <Swiper
          ref={swiperRef}
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards]}
          onSlideChange={({ realIndex }) => setCurrentIndex(realIndex)}>
          {news.map((item, index) => (
            <SwiperSlide key={index}>
              <NewsSliderCard {...item} readMoreLabel={readMoreLabel} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      {!!news.length && <StyledPagination amount={news.length} currentIndex={currentIndex} changeIndex={changeIndex} />}

      <MobileCardBody>
        <NewsTitle>{mobilePreviewItem?.title}</NewsTitle>

        <NewsPreviewText>{mobilePreviewItem?.previewText}</NewsPreviewText>

        <StyledLinkHandler to={mobilePreviewItem?.link?.href || `/news/${mobilePreviewItem?.id}`}>
          {mobilePreviewItem?.link?.title || readMoreLabel}
        </StyledLinkHandler>
      </MobileCardBody>
    </Wrapper>
  );
}
