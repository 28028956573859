import { useApi } from './ApiProvider';
import { IClaimRewardsResponse, IRewardStatus } from '../types/rewards';

const BASE_PATH = '/v2/staking/rewards/daily';

export const useRewardApi = () => {
  const apiInstance = useApi();

  const getRewardStatus = async (walletAddress: string) => {
    return (await apiInstance.get<IRewardStatus>(`${BASE_PATH}/status/${walletAddress}`)).data;
  };

  const claimDailyRewards = async (walletAddress: string) => {
    return (await apiInstance.post<IClaimRewardsResponse>(`${BASE_PATH}/claim/${walletAddress}`)).data;
  };

  return {
    getRewardStatus,
    claimDailyRewards,
  };
};
