import { useApi } from './ApiProvider';

export interface IGlobalAddressProps {
  id?: string;
  firstname: string;
  lastname: string;
  company?: string;
  city: string;
  street: string;
  postalCode: string;
  houseNumber: string;
  country: string;
  email: string;
  phone?: string;
  wallet?: string;
}

interface IUpdateAddressProps {
  wallet: string;
  id?: string;
  address: IGlobalAddressProps;
}

export const useAddressApi = () => {
  const apiInstance = useApi();
  const getAddress = async (wallet: string) => {
    return (await apiInstance.get<IGlobalAddressProps>(`/wallet/address/${wallet}`)).data;
  };

  const updateAddress = async (body: IUpdateAddressProps) => {
    return (await apiInstance.put<IGlobalAddressProps>(`/wallet/address`, body)).data;
  };

  return {
    getAddress,
    updateAddress,
  };
};
