import { useApi } from './ApiProvider';

export const useDashboardApi = () => {
  const apiInstance = useApi();
  const getStats = async (wallet: string) => {
    return (await apiInstance.get(`/wallet/${wallet}/stats`)).data;
  };

  const getDashboardData = async () => {
    return (await apiInstance.get(`/content/dashboard`)).data;
  };

  return {
    getStats,
    getDashboardData,
  };
};
