import { useApi } from '../ApiProvider';
import { IClaimCoinsResponse, IStakeTokensResponse, ITrainingStatus } from '../../types/stake';

const BASE_PATH = '/v2/staking/staking';

export const useTrainingApi = () => {
  const apiInstance = useApi();

  const claimCoins = async (walletAddress: string) => {
    return (await apiInstance.post<IClaimCoinsResponse>(`${BASE_PATH}/claim/${walletAddress}`)).data;
  };

  const stakeTokens = async (walletAddress: string) => {
    return (await apiInstance.post<IStakeTokensResponse>(`${BASE_PATH}/stake/${walletAddress}`)).data;
  };

  const getTrainingStatus = async (walletAddress: string) => {
    return (await apiInstance.get<ITrainingStatus>(`${BASE_PATH}/status/${walletAddress}`)).data;
  };

  return {
    claimCoins,
    stakeTokens,
    getTrainingStatus,
  };
};
