import { useApi } from './ApiProvider';

const BASE_PATH = '/wallet/level';

export interface LevelProps {
  level: string;
  points: number;
}

export const useLevelApi = () => {
  const apiInstance = useApi();

  const getLevelByWallet = async (wallet: string) => {
    return (await apiInstance.get(`${BASE_PATH}/${wallet}`)).data;
  };

  return {
    getLevelByWallet,
  };
};
