import { FC } from "react";
import styled, { keyframes } from "styled-components"

const Rotate = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: inline-block;
  border-top: var(--width, 5px) solid #FFF;
  border-right: var(--width, 5px) solid transparent;
  box-sizing: border-box;
  animation: ${Rotate} 1s linear infinite;
`;

interface ILoadingSpinnerProps {
  className?: string;
}

const LoadingSpinner: FC<ILoadingSpinnerProps> = ({ className }) => (
  <Spinner className={className} />
)

export default LoadingSpinner;