// General
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import styled from 'styled-components';

// Helpers
import { dispatchConnectWallet, dispatchWalletConnectedEvent } from '../../../utils/walletConnect/event';
import { WalletConnectTranslations } from '../app/Layout';

// Components
import ConnectWallet from './ConnectWallet';
import DisconnectWallet from './DisconnectWallet';
import { Button } from '../../common/Button';
import { WalletConnectDropDown } from './WalletConnectDropDown';

const WalletConnectWrapper = styled.div`
  display: contents;
`;

export const WalletConnectButton = styled(Button)`
  padding: 0 1.5rem;
`;

export interface IWalletConnectTranslations {
  connectButton: string;
  connectedButton: string;
  connectHeadline: string;
  connectText: string;
  disconnectHeadline: string;
  disconnectText: string;
  disconnectButton: string;
  yourWalletAddress: string;
  close: string;
}

export const StandaloneWalletConnectButton = () => {
  const t = useContext(WalletConnectTranslations);

  return (
    <Button onClick={dispatchConnectWallet} type={'button'}>
      {t?.connectButton}
    </Button>
  );
};

interface IAppProps {
  className?: string;
}

const WalletConnect: FC<IAppProps> = ({ className }) => {
  const [showModal, setShowModal] = useState(false);
  const { isConnected, address } = useAccount();

  const connect = useCallback(() => {
    if (isConnected) return;
    setShowModal(true);
  }, [isConnected]);

  const disconnect = useCallback(() => {
    if (!isConnected) return;
    setShowModal(true);
  }, [isConnected]);

  useEffect(() => {
    window.addEventListener('CONNECT_WALLET', connect);
    window.addEventListener('DISCONNECT_WALLET', disconnect);

    if (isConnected && address) {
      dispatchWalletConnectedEvent(address);
    }

    return () => {
      window.removeEventListener('CONNECT_WALLET', connect);
      window.removeEventListener('DISCONNECT_WALLET', disconnect);
    };
  }, [connect, disconnect, isConnected, address]);

  return (
    <WalletConnectWrapper className={className}>
      <WalletConnectDropDown connect={connect} disconnect={disconnect} />

      {
        showModal && (
          isConnected
            ? <DisconnectWallet close={() => setShowModal(false)} />
            : <ConnectWallet close={() => setShowModal(false)} />
        )
      }
    </WalletConnectWrapper>
  );
};

export default WalletConnect;
